<template>
    <v-card>
        <v-card-title class="headline grey lighten-2">
            {{ $t('Inventory Status Changes') }}
        </v-card-title>

        <v-container class="" style="max-width:unset;">
            <v-row>
                <v-col cols="2">
                    <v-checkbox
                        v-model="globalStatus"
                        :label="$t('Global Status')">
                    </v-checkbox>
                </v-col>

                <v-col>
                    <v-select v-model="globalSelect"
                        :disabled="!globalStatus"
                        :items="statusItems"
                        :label="$t('Global Status')"
                        return-object
                        @change="setGlobalStatus">
                    </v-select>
                </v-col>

                <v-col>
                    <v-select v-model="globalLocation"
                        :disabled="!globalStatus"
                        :items="locationItems"
                        :label="$t('Global Location')"
                        return-object
                        @change="setGlobalStatus">
                    </v-select>
                </v-col>
            </v-row>

            <div v-for="(value, key, idx) in stockChanges"
                :key="idx">
                <v-row>

                    <v-col cols="2">{{ value.type.toUpperCase() }}</v-col>
                    <v-col cols="3">{{ value.from.name }}</v-col>

                    <v-col>
                        <v-radio-group v-model="stockChanges[key].status"
                            row dense
                            :disabled="globalStatus"
                            class="mt-0 pt-0">
                            <v-radio
                                style="width:110px;"
                                v-for="(n, nidx) in statusItems"
                                :key="nidx"
                                :label="`${n.text}`"
                                :value="n.value"
                                @change="setStatus"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col>
                        <v-select v-model="stockChanges[key].location"
                            :disabled="globalStatus"
                            :items="locationItems"
                            :label="$t('Location')"
                            @change="setLocation">
                        </v-select>
                    </v-col>
                </v-row>
            </div>

            <v-card-actions>
                <v-btn
                    :disabled="!isDone"
                    @click="submitChanges">
                    {{ $t('submit') }}
                </v-btn>
            </v-card-actions>
        </v-container>

    </v-card>
</template>


<script>
import { rest } from '../_helpers'

export default {
    props: {
        systemId: null,
        projectId: null,
        changes: {
            default() {
                return {}
            },
        },
    },

    data() {
        return {
            globalStatus: false,
            globalSelect: null,
            globalLocation: null,
            stockChanges: this.changes,
            isDone: false,
            statusItems: [
                { text: this.$t('in_stock'), value: 1 },
                { text: this.$t('rejected'), value: 4 },
                { text: this.$t('unknown'), value: 0 },
                { text: this.$t('unusable'), value: 3 },
            ],
            locationItems: [],
        }
    },

    methods: {
        getDone() {
            let done = true
            Object.keys(this.stockChanges).forEach(i => {
                if (!Object.hasOwnProperty.call(this.stockChanges[i], 'status') || !Object.hasOwnProperty.call(this.stockChanges[i], 'location')) {
                    done = false
                }
            })
            return done
        },

        submitChanges() {
            const payload = []

            Object.keys(this.stockChanges).forEach(i => {
                const invId = this.stockChanges[i].from.id
                payload.push({
                    id: invId,
                    status: this.stockChanges[i].status,
                    type: this.stockChanges[i].type,
                    location: this.stockChanges[i].location,
                })
            })

            rest.setInvStatus(this.projectId, this.systemId, payload)
                .then(result => {
                    this.$emit('status-changed')
                })
        },

        setGlobalStatus() {
            Object.keys(this.stockChanges).forEach(i => {
                if (this.globalSelect) {
                    this.stockChanges[i].status = this.globalSelect.value
                }
                if (this.globalLocation) {
                    this.stockChanges[i].location = this.globalLocation.value
                }
            })
            this.isDone = this.getDone()
        },

        setStatus() {
            this.isDone = this.getDone()
        },

        setLocation() {
            this.isDone = this.getDone()
        },
    },

    created() {
        rest.getRows('location')
            .then(locations => {
                this.locationItems = locations
                    .map(loc => ({ text: this.$t(loc.name), value: loc.id }))
                    .sort((a, b) => a.text > b.text)
            })
    },

    watch: {
        changes(val) {
            this.stockChanges = val
            this.globalStatus = false
            this.globalLocation = null
            this.globalSelect = null
            this.isDone = this.getDone()
        },
    },
}
</script>
